import * as React from 'react';
import { TReactFCP, TReactFCR } from '../lib/types/utils';
import { UseStyles } from '../lib/types/mui';
import { Theme, Grid, Chip, makeStyles } from '@material-ui/core';
import { GatsbyImageWithIEPolyfillProps } from 'gatsby-image/withIEPolyfill';
import { Typography } from '../components/Typography';
import { Link } from '../components/Link';
import { GlobeAmericasIcon, CodeBranchIcon } from '../components/FAIcons';
import { Divider } from '../components/app/Divider';
import { GatsbyImageLink } from './GatsbyImageLink';

type ClassKey =
    'header' | 'title' | 'links' | 'link' | 'imageContainer' | 'image' | 'stack' | 'dividerContainer' | 'divider';
const useStyles = makeStyles<Theme, ClassKey>((theme: Theme) => ({
    header: {
        display: 'flex',
        margin: '0 auto',
        maxWidth: '30em',
        flexDirection: 'column',
        marginBottom: theme.spacing(6),
        padding: theme.spacing(2),
        // height: '100%', width: '100%'
    },
    title: {
        // paddingLeft: theme.spacing(1),
    },
    links: { flex: 0 },
    link: { color: theme.palette.custom.text.icon.lighter },
    stack: {
        ...theme.typography.sizing(1, 'mono'),
        borderRadius: theme.shape.borderRadius,
        borderColor: theme.palette.custom.card.labelArea.color,
        color: theme.palette.custom.pages.projects.previewCard.stack.color,
    },
    imageContainer: {
        flex: 1,
        height: '100%', width: '100%',
        display: 'flex',
        maxHeight: '400px',
        // marginBottom: theme.baseline.blockMarginBottom,
    },
    image: {
        boxShadow: theme.shadows[6],
        borderRadius: theme.shape.borderRadius
    },
    dividerContainer: { display: 'flex', alignItems: 'center' },
    divider: { height: '75%' }
}));

export type TProjectHeaderP = {
    title: string;
    category: string;
    type: string;
    spoiler: string;
    stack: string[];
    homepage?: string;
    repository?: string;
    featuredImage: { originalSrc: string; fluid: GatsbyImageWithIEPolyfillProps['fluid']; };
};

export function ProjectHeader(props: TReactFCP<TProjectHeaderP>): TReactFCR {
    const styles: UseStyles<ClassKey> = useStyles();
    const { title, category, type, spoiler, stack, homepage, repository, featuredImage }: TProjectHeaderP = props;
    const haveHomepage: boolean = homepage ? true : false;
    const haveRepository: boolean = repository ? true : false;
    const haveLinks: boolean = haveHomepage || haveRepository;
    return (
        <div>
            <div className={styles.header}>
                <Grid container wrap='nowrap' spacing={4}>
                    {haveLinks && (
                        <React.Fragment>
                            <Grid
                                item
                                container
                                direction='column'
                                justify='center'
                                spacing={1}
                                className={styles.links}
                            >
                                {haveHomepage && (
                                    <Link
                                        className={styles.link}
                                        variant='external'
                                        to={homepage!}
                                        disableUnderline
                                        inheritColor
                                    >
                                        <GlobeAmericasIcon fixedWidth />
                                    </Link>
                                )}
                                {haveRepository && (
                                    <Link
                                        className={styles.link}
                                        variant='external'
                                        to={repository!}
                                        disableUnderline
                                        inheritColor
                                    >
                                        <CodeBranchIcon fixedWidth />
                                    </Link>
                                )}
                            </Grid>
                            <Grid item className={styles.dividerContainer}>
                                <Divider className={styles.divider} orientation='vertical' />
                            </Grid>
                        </React.Fragment>
                    )}
                    <Grid item xs>
                        <div className={styles.title}>
                            <Typography variant='overline' noWrap>
                                {category} &middot; {type}
                            </Typography>
                            <Typography variant='h1'>{title!}</Typography>
                            <Typography variant='subtitle1' color='light'>{spoiler!}</Typography>
                            <Grid container spacing={2} alignItems='center'>
                                {(stack! as string[]).map((s: string) => (
                                    <Grid key={s} item>
                                        <Chip className={styles.stack} label={s} variant='outlined' size='small' />
                                    </Grid>
                                ))}
                            </Grid>
                        </div>
                    </Grid>
                </Grid>
            </div>
            <GatsbyImageLink
                to={featuredImage.originalSrc}
                className={styles.imageContainer}
                disableUnderline
                imageProps={{
                    className: styles.image,
                    objectFit: 'cover',
                    objectPosition: 'center center',
                    fluid: featuredImage.fluid,
                    style: { flex: 1, maxWidth: '100%', maxHeight: '100%', width: 'auto', height: 'auto' },
                    imgStyle: { width: '100%', height: '100%' }
                }}
            />
        </div>
    );
}
