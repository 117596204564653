import * as React from 'react';
import { TReactFCR } from '../lib/types/utils';
import { Link, TLinkP } from './Link';
import GatsbyImage, { GatsbyImageWithIEPolyfillProps } from 'gatsby-image/withIEPolyfill';

export type TGatsbyImageLinkP = Omit<TLinkP, 'variant'> & {
    imageProps?: GatsbyImageWithIEPolyfillProps;
};

export function GatsbyImageLink(props: TGatsbyImageLinkP): TReactFCR {
    const { imageProps, ...linkProps }: TGatsbyImageLinkP = props;
    return (
        <Link variant='internalTab' disableUnderline disableColor {...linkProps}>
            <GatsbyImage {...imageProps} />
        </Link>
    );
}
