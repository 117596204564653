import * as React from 'react';
import { TReactFCP, TReactFCR } from '../../lib/types/utils';
import { UseStyles } from '../../lib/types/mui';
import { Theme, makeStyles } from '@material-ui/core';
import { graphql } from 'gatsby';
import { Page } from '../../components/app/Page';
import { SEO } from '../../components/app/SEO';
import { getPageSlug } from '../../lib/pageLocation';
import { GQLProjectBeaconPageQuery } from '../../lib/types/graphql/__generated__/gatsby.gql';
import { Typography } from '../../components/Typography';
import { FluidObject } from 'gatsby-image';
import { ProjectHeader } from '../../components/ProjectHeader';
import { Link } from '../../components/Link';

type ClassKey = 'main';
const useStyles = makeStyles<Theme, ClassKey>((theme: Theme) => ({
    main: {
        flexGrow: 1,
        flexShrink: 0,
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'nowrap',
        padding: theme.spacing(4),
        maxWidth: theme.breakpoints.width('md'),
        margin: '0 auto',
        width: '100%'
    },
}));

export type TProjectBeaconP = {
    data: GQLProjectBeaconPageQuery;
};

function ProjectBeacon(props: TReactFCP<TProjectBeaconP>): TReactFCR {
    const styles: UseStyles<ClassKey> = useStyles();
    const { sitePage, projectsYaml }: GQLProjectBeaconPageQuery = props.data;
    const {
        title, category, type, spoiler, description, stack, links, featuredImage
    }: NonNullable<GQLProjectBeaconPageQuery['projectsYaml']> = projectsYaml!;
    return (
        <main className={styles.main}>
            <SEO
                slug={sitePage!.path!}
                title={title!}
                seoTitle={`Codename: ${title!}`}
                description={description!}
                image={{
                    src: featuredImage!.childImageSharp!.original!.src!,
                    width: featuredImage!.childImageSharp!.original!.width!,
                    height: featuredImage!.childImageSharp!.original!.height!
                }}
            />
            <ProjectHeader
                title={title!}
                category={category!}
                type={type!}
                spoiler={spoiler!}
                stack={stack! as string[]}
                homepage={links!.homepage!}
                repository={links!.repository!}
                featuredImage={{
                    originalSrc: featuredImage!.childImageSharp!.original!.src!,
                    fluid: featuredImage!.childImageSharp!.fluid! as FluidObject
                }}
            />
            <Typography variant='h2' align='center' gutterTop paragraph>About</Typography>
            <Typography paragraph>
                This project was built for an interview at{` `}
                <Link variant='external' to='https://forgeglobal.com/'>Forge Global</Link> when they were known as
                Equidate in 2015.
            </Typography>
            <Typography paragraph>
                The idea is to discover someone's online presence, e.g. social media usernames and email addresses,
                given their first and last name along with any other initial clues, e.g. their Twitter handle. The
                final version used a set of hueristics to guess social media usernames and email addresses based on
                varitions of a person's full name.
            </Typography>
            <Typography paragraph>
                I didn't have enough time to implement the scrapers or API clients to interact with services like Gmail
                or AngelList, but the skeletons for their implementation are in place and documented with potential
                approaches and caveats to discover and verify an identity. Since I care deeply about great documentation
                the project also includes a automatically generated{` `}
                <Link variant='external' to='https://johnrichter.github.io/beacon/'>documentation website</Link> built
                with <Link variant='external' to='https://www.sphinx-doc.org/'>Sphinx</Link>.
            </Typography>
        </main>
    );
}

export default (props: TReactFCP<TProjectBeaconP>): TReactFCR => (
    <Page component={ProjectBeacon} cprops={props} />
);

export const query = graphql`
    query ProjectBeaconPage {
        sitePage(internalComponentName: {eq: "ComponentProjectsBeacon"}) {
            path
        }
        projectsYaml(pid: {eq: "beacon"}) {
            title
            category
            type
            spoiler
            description
            stack
            links {
                homepage
                repository
            }
            featuredImage {
                childImageSharp {
                    fluid(maxWidth: 1000) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                    original {
                        src
                        width
                        height
                    }
                }
            }
        }
    }
`;
